/* eslint-disable */
import { ReactComponent as DollarInCircle } from '../../../../assets/icons/DollarInCircle.svg';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import TypeCell from './TableCells/TypeCell';
import TextEllipsis from './TableCells/TextEllipsis';
import TextCell from './TableCells/TextCell';
import { StatusCell } from './TableCells/StatusCell';
import EditableCell from './TableCells/EditableCell';
import { DateCell } from './TableCells/DateCell';
import QuantityCell from './TableCells/QuantityCell';
import NonBillableCell from './TableCells/NonBillableCell';
import BillableCell from './TableCells/BillableCell';

export const TABLE_ROWS_COUNT = 20;

export const TypeRadio = [
  { label: 'All', value: 'All' },
  { label: 'Time', value: 'Time', buttonWidth: '179px', icon: <Clock /> },
  { label: 'Expense', value: 'Expense', buttonWidth: '179px', icon: <DollarInCircle /> },
];

export const expenseCategoriesOptions = [
  { label: 'Answer Fee', value: 'Answer Fee', amount: 68 },
  { label: 'Certified Mailing', value: 'Certified Mailing', amount: 5.54 },
  { label: 'Copying Fees', value: 'Copying Fees', amount: 0 },
  { label: 'Court Appearance', value: 'Court Appearance', amount: 0 },
  { label: 'Court Costs', value: 'Court Costs', amount: 0 },
  { label: 'Investigation Fees', value: 'Investigation Fees', amount: 0 },
  { label: 'Reimbursed Service', value: 'Reimbursed Service', amount: 0 },
  { label: 'Settlement Fee', value: 'Settlement Fee', amount: 0 },
  { label: 'Superior Court Costs', value: 'Superior Court Costs', amount: 0 },
  { label: 'Background Check Fee', value: 'Background Check Fee', amount: 0 },
  { label: 'BK Filing Fees', value: 'BK Filing Fees', amount: 199 },
  { label: 'Civil Filing Fees', value: 'Civil Filing Fees', amount: 0 },
  { label: 'Filing & Court Fees', value: 'Filing & Court Fees', amount: 0 },
  { label: 'Other Cost', value: 'Other Cost', amount: 0 },
  { label: 'Postage and Delivery Fees', value: 'Postage and Delivery Fees', amount: 0 },
  { label: 'Services', value: 'Services', amount: 0 },
];

export const TABLE_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'type',
    width: 4,
    Cell: TypeCell,
  },
  {
    Header: 'Qty',
    width: 10,
    accessor: 'quantity',
    Cell: QuantityCell,
  },
  {
    Header: 'Description',
    width: 20,
    accessor: 'description',
    Cell: TextEllipsis,
  },
  {
    width: 10,
    Header: 'Rate ($)',
    accessor: 'rate',
    Cell: TextCell,
  },
  {
    Header: 'Non-billable ($)',
    accessor: 'nonBillable',
    width: 10,
    Cell: NonBillableCell,
  },
  {
    Header: 'Billable ($)',
    accessor: 'billable',
    width: 10,
    Cell: BillableCell,
  },
  {
    Header: 'Date ',
    accessor: 'date',
    width: 10,
    Cell: DateCell,
  },
  {
    Header: 'User',
    accessor: 'user',
    width: 15,
    Cell: TextCell,
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: 15,
    Cell: TextEllipsis,
  },
  {
    Header: 'Invoice Status',
    accessor: 'invoiceStatus',
    width: 10,
    Cell: StatusCell,
  },
  {
    Header: '',
    show: true,
    accessor: 'control',
    width: 8,
    Cell: (props) => {
      return props.row.original?.isTotalRow ? null : <EditableCell {...props} />;
    },
  },
];

export const EDIT_EXPENSE_ENTRY_LEFT_COLUMN = [
  {
    label: 'Handling Attorney',
    name: 'firmUser',
    placeholder: 'Select handling attorney',
    width: '326px',
    isMandatory: true,
    isDropdown: true,
    height: '48px',
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter description',
    width: '326px',
    rows: 10,
    isMultiLineTextField: true,
    isMandatory: true,
  },
];
export const EDIT_EXPENSE_ENTRY_RIGHT_COLUMN = [
  {
    label: 'Date',
    name: 'date',
    placeholder: '',
    width: '326px',
    height: '48px',
    isDatePicker: true,
    isMandatory: true,
    dateFormat: 'MM/DD/YYYY',
  },
  {
    label: 'Expense category',
    name: 'category',
    placeholder: 'Select category',
    width: '326px',
    isMandatory: true,
    isDropdown: true,
    height: 'auto',
  },
  {
    label: 'Amount',
    name: 'amount',
    placeholder: 'Enter amount',
    width: '166px',
    isMandatory: true,
  },
  {
    name: 'defaultAmount',
    width: '190px',
    text: 'Default Amount',
    isButton: true,
  },
  {
    label: 'Non-billable',
    name: 'isNonBillable',
    isCheckbox: true,
    isMandatory: false,
  },
];
export const EDIT_TIME_ENTRY_LEFT_COLUMN = [
  {
    label: 'Handling Attorney',
    name: 'firmUser',
    placeholder: 'Select handling attorney',
    width: '326px',
    isMandatory: true,
    isDropdown: true,
    height: '48px',
  },
  {
    label: 'Legal Category',
    name: 'category',
    placeholder: 'Select legal category',
    width: '326px',
    isMandatory: true,
    isDropdown: true,
    height: '48px',
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter description',
    width: '326px',
    rows: 4,
    isMultiLineTextField: true,
    isMandatory: true,
    maxLength: 2000,
  },
];
export const EDIT_TIME_ENTRY_RIGHT_COLUMN = [
  {
    label: 'Date',
    name: 'date',
    placeholder: '',
    width: '326px',
    height: '48px',
    isDatePicker: true,
    isMandatory: true,
    dateFormat: 'MM/DD/YYYY',
  },
  {
    label: 'Duration (h)',
    name: 'duration',
    placeholder: 'Enter duration(h)',
    isMandatory: true,
    width: '326px',
  },
  {
    label: 'Rate (per hour)',
    name: 'rate',
    placeholder: 'Enter Rate (per hour)',
    width: '190px',
    isMandatory: true,
  },
  {
    name: 'defaultRate',
    width: '190px',
    text: 'Default Rate',
    isButton: true,
  },
  {
    label: 'Non-billable',
    name: 'isNonBillable',
    isCheckbox: true,
    isMandatory: false,
  },
];
